<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <div>
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item label="订单编号:">
                        <el-input class="s-input" v-model="queryParams.ordersn" placeholder="请输入订单编号" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="用户id:">
                      <el-input class="s-input" v-model="queryParams.uid" placeholder="请输入用户id" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="方式:">
                        <el-select v-model="queryParams.ty" placeholder="请选择方式" clearable @change="changeQuery">
                            <el-option label="支出" :value="2"></el-option>
                            <el-option label="收入" :value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <div style="float: right">
                        <el-button type="primary" @click="changeQuery()">搜索</el-button>
                    </div>
                </el-form>
            </div>
            <div class="dataList">
                <el-table :data="pageInfo.list" style="margin-top: 20px; width: 100%;" v-loading="loading" element-loading-text="数据加载中" stripe border ref="multipleTable">
                    <el-table-column prop="id" label="id" width="100" align="center"></el-table-column>
                    <el-table-column prop="uid" label="用户id" width="120" align="center"></el-table-column>
                    <el-table-column prop="ordersn" label="订单号" width="250" align="center"></el-table-column>
                    <el-table-column prop="before" label="记录前" width="180" align="center"></el-table-column>
                    <el-table-column prop="num" label="数量" width="180" align="center"></el-table-column>
                    <el-table-column prop="remark" label="备注" align="center"></el-table-column>
                    <el-table-column prop="created_at" label="创建时间" width="200" align="center"></el-table-column>
                    <el-table-column label="收入/支出" prop="ty" align="center" width="120">
                        <template slot-scope="scope">
                            <el-tag type="primary" v-if="scope.row.ty === 1">微信</el-tag>
                            <el-tag type="primary" v-if="scope.row.ty === 2">支付宝</el-tag>
                            <el-tag type="primary" v-if="scope.row.ty === 3">银行卡</el-tag>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import Url from '@/components/Url';
import { request } from '@/common/request';
import { deal } from '@/common/main';
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            formData: {},
            form: {},
            id: 0,
            addVisible: false,
            confirmVisible: false,
            confirmContent: '',
            menuList: ['资金', '积分记录'],
            pageInfo: {},
            queryParams: {
                page: 1,
                pagesize: 10
            },
            ty: 1,
            pay_number: null,

        };
    },
    created() {
        this.loadData();
    },
    methods: {
        edit(index, row) {
            this.disabled = true
            this.title = '编辑';
            const item = this.pageInfo.list[index];
            let time = [item.stime, item.etime]
            //处理时间
            this.form = Object.assign({}, item);
            this.form.price = this.form.price + ''
            this.$set(this.form, 'time', time);
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        state(row, state) {
            if (state == 3) {
                this.ty = 1
                this.confirmContent = '确定是否驳回?';
            }
            else {
                this.ty = 2
                this.confirmContent = '确定是否通过?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state
            this.confirmVisible = true;
        },
        //增加编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        var url = '/wallet/carry/edit';
                        request.post(url, this.form).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.loadData();
                                this.$message.success('编辑成功');
                                this.$refs.form.clearValidate()
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        },
        loadData() {
            this.loading = true;
            request.get('/wallet/integral/log/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list.map(function (val) {
                        val.pay_time = deal.timestamp(val.pay_time, 'unix');
                        val.pay_state = val.pay_state == 0 ? '支出' : '收入';
                        val.reason = val.reaso ? val.reason : val.remarks
                        return val;
                    })

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 确定下线
        stateData() {
            if (this.ty === 1) {
                request.post('/wallet/carry/edit', this.form).then(ret => {
                    if (ret.code == 1) {
                        this.$message.success('操作成功');
                        this.loadData();
                        this.confirmVisible = false;
                    } else {
                        this.$message.error(ret.msg);
                    }
                });
            } else {
                if (this.$refs.form) {
                    console.log(555, this.form)
                    this.$refs.form.validate(valid => {
                        console.log(11111, this.form)
                        if (valid) {
                            request.post('/wallet/carry/edit', this.form).then(ret => {
                                if (ret.code == 1) {
                                    this.$message.success('操作成功');
                                    this.loadData();
                                    this.confirmVisible = false;
                                } else {
                                    this.$message.error(ret.msg);
                                }
                            });
                        }
                        else {
                            this.$message.error('请补全信息');
                            return false;
                        }
                    });
                };
            }

        },
        // 同步审核
        sync(id) {
            request.post('/wallet/carry/syn', { id: id }).then(ret => {
                if (ret.code == 1) {
                    this.isSync = true
                    this.$message.success('同步成功')
                    this.loadData();
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        detail(index, id) {
            this.$router.push({ path: '/custom-Detail', query: { id: id } })
        }
    }
};
</script>

<style  scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}
/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}
</style>